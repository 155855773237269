exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-l-tsx": () => import("./../../../src/pages/l.tsx" /* webpackChunkName: "component---src-pages-l-tsx" */),
  "component---src-pages-link-tsx": () => import("./../../../src/pages/link.tsx" /* webpackChunkName: "component---src-pages-link-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-spotify-tsx": () => import("./../../../src/pages/spotify.tsx" /* webpackChunkName: "component---src-pages-spotify-tsx" */),
  "component---src-pages-youtube-tsx": () => import("./../../../src/pages/youtube.tsx" /* webpackChunkName: "component---src-pages-youtube-tsx" */)
}

